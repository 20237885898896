<!-- 班级管理 -->
<template>
  <div class="school_home_page">
    <myTable title="班级管理" :columns="classColumns" :list="classList">
      <template slot="top_block">
        <div class="code_block">
          <el-button @click="addStu" class="table_top_btn_default"
            >添加班级 +</el-button
          >
        </div>
      </template>
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="handleEdit(item.data.row, 'view')">
            查看
          </div>
          <div class="table_btn" @click="handleEdit(item.data.row, 'edit')">
            编辑
          </div>
          <div class="table_btn" @click="handleEdit(item.data.row, 'del')">
            删除
          </div>
        </div>
      </template>
    </myTable>
    <comdialog
      :list="fromList"
      ref="comdialog"
      :params.sync="form"
      :edit="alertStatus"
      :title="operateTitle"
      @onSubmit="onSubmit"
      @close="comdialogClose"
    ></comdialog>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import myTable from "@/components/admin/table.vue";
import comdialog from "@/components/admin/editDialog.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "headerSchool",
  components: {
    myTable,
    comdialog,
    comPagination,
  },
  data() {
    return {
      //班级列表
      classColumns: [
        {
          prop: "id",
          label: "编号",
        },
        {
          prop: "name",
          label: "名称",
        },
        {
          prop: "type",
          label: "班级",
        },
        {
          prop: "teacher",
          label: "班级教师",
          align: "left",
        },
      ],
      classList: [],
      // 弹窗状态  新增/编辑/查看
      alertStatus: "",
      form: {
        //已选中的数据 编辑
        name: "",
        type: "",
      },
      operateTitle: "编辑班级",
      alertId: "",
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      fromList: [
        {
          prop: "name",
          type: "text",
          label: "名称",
          required: false,
          rules: [
            {
              required: false,
              message: "请输入班级名称",
              trigger: "blur",
            },
          ],
        },
        {
          prop: "type",
          type: "radio",
          label: "所属班级",
          required: false,
          options: [
            {
              label: "小班",
              value: "2",
            },
            {
              label: "中班",
              value: "1",
            },
            {
              label: "大班",
              value: "0",
            },
          ],
          rules: [
            {
              required: false,
              message: "请选择所属班级",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getClassInfo();
    },
    onSubmit(val) {
      // 新增
      console.log(val);
      if (!val.name) {
        this.$message.error("请输入班级名称");
        return;
      } else if (!val.type) {
        this.$message.error("请选择所属班级");
        return;
      }
      if (this.alertStatus === "add") {
        let data = {
          ...val,
          schoolId: this.$store.state.userInfo.schoolId,
        };
        this.api.admin.addGrade(data).then((res) => {
          if (res.flag) {
            this.$refs.comdialog.isClose();
          }

          this.getClassInfo();

          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
        });
      } else {
        this.api.admin.updateGrade(val, this.alertId).then((res) => {
          console.log(res);
          if (res.flag) {
            this.$refs.comdialog.isClose();
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
            this.getClassInfo();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
        });
      }
    },
    //关闭编辑弹窗
    comdialogClose() {
      this.form = {
        name: "",
        type: "",
      };
      this.alertId = "";
    },
    //打开编辑弹窗
    addStu() {
      this.$refs.comdialog.isOpen();
      this.alertStatus = "add";
      this.operateTitle = "新增班级";
      this.form = {
        name: "",
        type: "",
      };
    },
    // 编辑/删除弹窗
    handleEdit(val, type) {
      this.operateTitle = "编辑班级";
      this.alertStatus = type;
      if (type === "edit") {
        this.$refs.comdialog.isOpen();
        this.form = {
          name: val.name,
          type: val.type == "小班" ? "2" : val.type == "中班" ? "1" : "0",
        };
        this.alertId = val.id;
      } else if (type === "add") {
        this.$refs.comdialog.isOpen();
        this.alertId = val.id;
        this.form = {
          name: "",
          type: 1,
        };
      } else if (type === "del") {
        this.getStuInfo(val.id,(data)=>{
          //判断是否为非空班级
          if(val.teacher || data.length){
            this.$notice({
              message: '请先清空该班级的学员和老师',
            }).isShow();
            return;
          }else{
            this.$confirm("此操作将永久删除该班级, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.api.admin.updateDeleteStatusGrade(val.id).then((res) => {
                  if (res.flag) {
                    this.$notice({
                      type: "success",
                      message: "删除成功!",
                    }).isShow();
                  } else {
                    this.$notice({
                      message: res.message,
                    }).isShow();
                  }
                  this.getClassInfo();
                });
              })
              .catch(() => {
                this.$notice({
                  type: "warn",
                  message: "已取消删除",
                }).isShow();
              });
          }
        });
      } else {
        let type = val.type == "小班" ? "2" : val.type == "中班" ? "1" : "0";
        val.type = type;
        this.$router.push({
          path: "/admin/classHome",
          query: {
            ...val,
          },
        });
      }
    },
    //分页 todo 待测试
    changePage(val) {
      console.log(val);
      this.params.page = val;
      this.getClassInfo();
    },
    getClassInfo() {
      this.api.admin.findGradesDetails().then((res) => {
        let arr = [];
        res.data.forEach((item) => {
          let teacherList = [];
          item.atteTeacherList &&
            item.atteTeacherList.forEach((val) => {
              teacherList.push(val.name);
            });
          let str = teacherList.join(",");

          arr.push({
            id: item.atteGrade.id,
            name: item.atteGrade.name,
            type:
              item.atteGrade.type === "0"
                ? "大班"
                : item.atteGrade.type === "1"
                ? "中班"
                : item.atteGrade.type === "2"
                ? "小班"
                : "",
            passCount: item.passCount + "/16节",
            stuCount: item.stuCount || 0,
            teacher: str,
          });
        });
        this.classList = arr;
      });
    },
    //获取学生信息
    getStuInfo(gradeId,fn) {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: gradeId,
        page: 1,
        size: 10,
      };
      this.api.admin.findStuInfoByGradeId(data).then((res) => {
        fn && fn(res.data.list);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>